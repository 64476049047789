:root {
	--color-primary: #1abd5e;
	--color-primary-hover: #199952;
	--color-secondary: #ff9800;
	--color-secondary-hover: #e68a00;
	--color-success: #4caf50;
	--color-success-hover: #439746;
	--color-error: #f44336;
	--color-error-hover: #d32f2f;
	--color-info: #2196f3;
	--color-info-hover: #1976d2;
	--color-warning: #ffeb3b;
	--color-warning-hover: #f0c22b;
}
